import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import ButtonMultimedia from '@/components/MultimediaManager/ButtonMultimedia/ButtonMultimedia.vue'
import SvgIcon from '@/components/vue-chat/components/SvgIcon/SvgIcon'
import NewAssistantEditor from '@/components/Assistants/NewAssistantEditor.vue'
import Contacts from '@/views/Pages/Developers/ChannelIntegration/WhatsAppChannel/Contacts/Contacts.vue'
import WebChatContact from '@/components/WebChat/Contacts/Contacts.vue'
import AiService from '@/services/ai/ai.service.js'
import EventBus from '@/util/EventBus'
import { Assistant } from '@/models/Ai/Assistant/Assistant.js'

export default {
  name: 'KnowledgeEditor',
  components: {
    Contacts,
    HeaderTopDashboard,
    ButtonMultimedia,
    NewAssistantEditor,
    SvgIcon,
    WebChatContact,
  },
  props: {
     assistant: {
      required: true,
      type: Object,
    },
  },
  data () {
    return {
      items: [],
      itemsPerPage: 5,
      page: 1,
      total: 0,
      disabledPagination: true,
      options: {
        page: 1,
        itemsPerPage: 5,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
      },
      searchTerm: '',
      debouncedInput: '',
      timeout: null,
      webUrlDialog: false,
      webUrlPreviewContent: null,
      webUrlPreviewInResponse: '',
      timeoutWebInput: null,
      isPreviewInfoUrl: false,
      currentFile: {
        audience: 0,
        name: '',
        filename: '',
        path: '',
        bytes: '',
        created_at: '',
        type: '',
        url: '',
        description: '',
        serverUrl: '',
        extension: '',
        size: 0,
        icon: '',
      },
      currentFileHelper: {},
      fileInfoDialog: false,
      audienceDialog: false,
      fileIdToAddToRemove: null,
      removeFileDialog: false,
      loading: false,
      loadingWebUrlContent: false,
      audienceOptions: [
        { value: 0, text: this.$t('Publico'), icon: 'fas fa-eye' },
        { value: 1, text: this.$t('Privado'), icon: 'fas fa-eye-slash' },
      ],
      addFileDialog: false,
      fileInfo: {},
    }
  },
  methods: {
    preRemoveFile (item) {
      this.currentFile = item
      this.removeFileDialog = true
    },

    removeFile () {
      this.loading = true
      if (this.currentFile.type === 0) {
        this.assistant.fileIdsToRemove = [this.currentFile.file_id]
      }

      if (this.currentFile.type === 1) {
        this.assistant.fileUrlIdsToRemove = [this.currentFile.id]
      }

      AiService.removeFile(this.assistant).then((assistantData) => {
        this.loading = false
        this.assistant.setData(assistantData)
        this.assistant.fileIdsToRemove = []
        this.assistant.fileUrlIdsToRemove = []
        this.removeFileDialog = false
        this.page = this.page > 1 && this.paginatedItems.length === 0 ? this.page = 1 : this.page
        EventBus.$emit('updateAssistantFromTable', assistantData)
      })
    },

    saveFileAudience () {
      this.loading = true
      EventBus.$emit('showLoading', true)
      EventBus.$emit('hideAlert')
      AiService.saveFileAudience({ id: this.currentFile.id, audience: this.currentFileHelper.audience }).then(() => {
        this.loading = false
        this.currentFile.audience = this.currentFileHelper.audience
        EventBus.$emit('showAlert', 'success', this.$t('Se ha cambiado la audiencia con éxito.'))
        EventBus.$emit('showLoading', false)
      }, () => {
        EventBus.$emit('showAlert', 'danger', this.$t('Ocurrio un error al cambiar la audiencia del documento o url'))
        EventBus.$emit('showLoading', false)
      })
    },
    preChangeAudience (item) {
      this.currentFile = item
      this.currentFileHelper = JSON.parse(JSON.stringify(this.currentFile))
      this.audienceDialog = true
    },

    showInfoFromFileOrUrl (item) {
      if (item.type === Assistant.FILE_TYPE_URL) {
        this.showPreviewInfoUrl(item)
      }

      if (item.type === Assistant.FILE_TYPE_FILE) {
        this.currentFile = item
        this.currentFile.icon = this.getIconByExtension(this.getFileExtension(this.currentFile.filename))
        this.showInfoFromFile()
      }
    },
    getFileExtension (filename) {
      const cleanFilename = filename.replace(/\.*$/, '')
      const ext = cleanFilename.split('.').pop()
      return ext === cleanFilename ? '' : ext
    },
    showInfoFromFile () {
      this.fileInfoDialog = true
    },
    showPreviewInfoUrl (item) {
      this.currentFile.url = item.url
      this.webUrlPreviewContent = null
      this.isPreviewInfoUrl = true
      this.webUrlDialog = true
      this.getWebUrlContent()
    },
    prepareWebUrlDialog () {
      this.webUrlPreviewContent = null
      this.currentFile.url = ''
      this.webUrlDialog = true
    },
    debounceInput () {
      this.loadingWebUrlContent = true
      clearTimeout(this.timeoutWebInput)
      this.timeoutWebInput = setTimeout(() => {
        if (!this.currentFile.url || this.currentFile.url.trim() === '') {
          this.loadingWebUrlContent = false
        } else {
          this.getWebUrlContent()
        }
      }, 700)
    },
    getWebUrlContent () {
      AiService.getWebUrlContent({ url: this.currentFile.url }).then((data) => {
        this.webUrlPreviewContent = data.content
        this.webUrlPreviewInResponse = data.url
      }).catch(() => {
        this.loadingWebUrlContent = false
      }).finally(() => {
        this.loadingWebUrlContent = false
      })
    },
    getCapitalizedPath (path) {
      if (!path) return ''
      return path[0].toUpperCase() + path.slice(1).toLowerCase()
    },
    getIconByExtension (extension) {
      const extensionToIconMap = {
        pdf: 'fas fa-file-pdf',
        xls: 'fas fa-file-excel',
        xlsx: 'fas fa-file-excel',
        doc: 'fas fa-file-word',
        docx: 'fas fa-file-word',
        ppt: 'fas fa-file-powerpoint',
        pptx: 'fas fa-file-powerpoint',
        txt: 'fas fa-file-lines',
        csv: 'fas fa-file-csv',
      }
      return extensionToIconMap[extension] || null
    },
    async selectMultimediaFile (url, fileInfo) {
      this.currentFile.name = fileInfo.name
      this.currentFile.serverUrl = url
      this.currentFile.path = fileInfo.path
      this.currentFile.extension = fileInfo.extension
      this.currentFile.size = fileInfo.size
      this.currentFile.icon = this.getIconByExtension(fileInfo.extension)
      this.currentFile.audience = 0
      this.addFileDialog = true
    },

    saveSelectedFile () {
      EventBus.$emit('showLoading', true, this.$t('Guardando archivo...'))
      this.assistant.filesToStore.push(this.currentFile)
      this.loading = true
      AiService.saveFiles(this.assistant).then((assistantData) => {
        this.assistant.setData(assistantData)
        EventBus.$emit('updateAssistantFromTable', assistantData)
        EventBus.$emit('showAlert', 'success', this.$t('Se ha guardado el archivo con éxito.'))
        EventBus.$emit('showLoading', false)
        this.addFileDialog = false
        this.loading = false
      }, (res) => {
        EventBus.$emit('showAlert', 'danger', this.$t('Error al guardar el archivo.'))
        EventBus.$emit('showLoading', false)
        this.addFileDialog = false
        this.loading = false
       })
    },

    createFileUrl () {
      this.loading = true
      EventBus.$emit('showLoading', true, this.$t('Guardando url...'))
      AiService.createFileUrl({ url: this.currentFile.url, assistantId: this.assistant.id, audience: this.currentFile.audience, description: this.currentFile.description }).then((assistantData) => {
        this.loading = false
        EventBus.$emit('updateAssistantFromTable', assistantData)
        this.assistant.setData(assistantData)
        EventBus.$emit('showAlert', 'success', this.$t('Se ha guardado la url con éxito.'))
        EventBus.$emit('showLoading', false)
        this.webUrlDialog = false
      }, (res) => {
        this.loading = false
        EventBus.$emit('showAlert', 'danger', this.$t('Error al guardar la url.'))
        EventBus.$emit('showLoading', false)
       })
    },
  },
  computed: {
    headers () {
      return [
        {
          text: this.$t('Nombre'),
          value: 'filename',
        },
        {
          text: this.$t('fecha de creación'),
          value: 'created_at',
        },
        {
          text: this.$t('Actualización'),
          value: 'updated_at',
        },
        {
          text: this.$t('Tipo de archivo'),
          value: 'type',
        },
        {
          text: this.$t('Audiencia'),
          value: 'audience',
        },
        {
          text: this.$t('Acciones'),
          value: 'actions',
          sortable: false,
        },
      ]
    },
    searchTermHelper: {
      get: function () {
        return this.debouncedInput
      },
      set: function (val) {
        this.debouncedInput = val
        if (this.timeout !== null) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.searchTerm = val
          this.options.page = 1
        }, 700)
      },
    },
    params (nv) {
      return {
        ...this.options,
        query: this.searchTerm,
      }
    },
    filteredItems () {
      const searchTerm = this.searchTerm.toLowerCase()
      return this.assistant.files.filter(item => {
        return Object.values(item).some(value =>
          String(value).toLowerCase().includes(searchTerm),
        )
      })
    },
    paginatedItems () {
      const sortedItems = [...this.filteredItems]
      if (this.options.sortBy && this.options.sortBy.length > 0) {
        sortedItems.sort((a, b) => {
          for (let i = 0; i < this.options.sortBy.length; i++) {
            const sortKey = this.options.sortBy[i]
            const sortDesc = this.options.sortDesc[i]
            if (a[sortKey] < b[sortKey]) return sortDesc ? 1 : -1
            if (a[sortKey] > b[sortKey]) return sortDesc ? -1 : 1
          }
          return 0
        })
      }

      const start = (this.options.page - 1) * this.options.itemsPerPage
      const end = start + this.options.itemsPerPage
      return sortedItems.slice(start, end)
    },
    totalItems () {
      return this.filteredItems.length
    },
    pageCount () {
      return Math.ceil(this.totalItems / this.itemsPerPage)
    },
  },
  watch: {
    webUrlDialog: {
      handler: function () {
        if (!this.webUrlDialog) {
          this.isPreviewInfoUrl = false
        }
      },
      deep: true,
    },
   },
  mounted () {
    this.disabledPagination = false
  },
  created () {

  },
}
