var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"px-1"},[_c('v-col',{staticClass:"mt-n3",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"v-card-grey mb-3 px-0 "},[_c('v-card-title',{staticClass:"pt-0 pb-0 mb-0 card-title-bloque"},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('p',{staticClass:"tit_card pt-2"},[_vm._v(" "+_vm._s(_vm.$t("Conocimiento"))+" ")])]),_c('v-col',[_c('v-text-field',{staticClass:"input-style font-size-input text-light-input placeholder-light input-icon",attrs:{"hide-details":"","dense":"","flat":"","filled":"","solo":"","placeholder":_vm.$t('Buscar')},model:{value:(_vm.searchTermHelper),callback:function ($$v) {_vm.searchTermHelper=$$v},expression:"searchTermHelper"}},[_c('template',{slot:"prepend-inner"},[_c('v-icon',{attrs:{"color":"#adb5bd","size":".875rem"}},[_vm._v(" fas fa-search ")])],1)],2)],1)],1)],1),_c('v-card-text',{staticClass:"px-0 py-0 pb-4"},[_c('v-row',{staticClass:"mt-0"},[_c('v-col',[_c('v-data-table',{staticClass:"table thead-light",attrs:{"footer-props":{'items-per-page-options': [5, 10, 15], 'items-per-page-text': _vm.$t('Items por página') },"mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.paginatedItems,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"options":_vm.options,"server-items-length":_vm.assistant.files.length,"loading-text":_vm.$t('Cargando datos... Espere por favor.'),"no-data-text":_vm.$t('No hay resultados.')},on:{"update:page":function($event){_vm.page=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.filename",fn:function(ref){
var item = ref.item;
return [(item.type === 0)?_c('span',[_vm._v(" "+_vm._s(item.filename)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("shortChars")(item.url,25))+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.type === 0)?_c('span',[_vm._v(" "+_vm._s(item.filename.split('.')[item.filename.split('.').length -1])+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('Enlace web'))+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 0)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('No Verificado'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('Verificado'))+" ")])]}},{key:"item.audience",fn:function(ref){
var item = ref.item;
return [(item.audience === 0)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('Publico'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('Privado'))+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("lastModified")(item.created_at))+" ")])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("lastModified")(item.updated_at))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"blue-grey darken-1"},on:{"click":function($event){return _vm.showInfoFromFileOrUrl(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"16"}},[_vm._v(" fas fa-info ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Información'))+" ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"blue-grey darken-1"},on:{"click":function($event){return _vm.preChangeAudience(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"16"}},[_vm._v(" fas fa-edit ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Editar'))+" ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red lighten-2"},on:{"click":function($event){return _vm.preRemoveFile(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"16"}},[_vm._v(" far fa-trash-alt ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Eliminar')))])])]}}])}),_c('v-row',[_c('v-col',{staticClass:"ml-auto d-flex justify-end",attrs:{"cols":"6"}},[(_vm.assistant.files.length)?_c('v-pagination',{staticClass:"pagination",staticStyle:{"margin-top":"-52px"},attrs:{"prev-icon":"fa fa-angle-left","next-icon":"fa fa-angle-right","color":"#0094de","length":_vm.pageCount,"total-visible":10,"disabled":_vm.disabledPagination,"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-end flex-grow-1"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-2 mb-3 float-right mr-2",attrs:{"elevation":"0","rounded":"","right":"","small":"","color":"light-green darken-2 white--text"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('Añadir conocimiento'))+" "),_c('v-icon',{staticClass:"ml-2",attrs:{"color":"white"}},[_vm._v(" fas fa-plus-circle ")])],1)]}}])},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_c('button-multimedia',{attrs:{"button-text":_vm.$t('Gestor Multimedia'),"current-root-directory":"adjuntos","directories":['adjuntos', 'csv'],"no-border":true},on:{"onSelectedMultimediaFile":_vm.selectMultimediaFile,"onSelectedCsv":_vm.selectMultimediaFile}})],1)],1),_c('v-list-item',[_c('v-list-item-title',[_c('v-btn',{staticClass:"ml-2 no-border",attrs:{"dark":"","outlined":"","color":"light-blue darken-2"},on:{"click":_vm.prepareWebUrlDialog}},[_c('v-icon',{attrs:{"size":"15","left":""}},[_vm._v(" fas fa-link ")]),_vm._v(" "+_vm._s(_vm.$t('Enlace web'))+" ")],1)],1)],1)],1)],1)],1)])],1)],1)],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"35%"},model:{value:(_vm.webUrlDialog),callback:function ($$v) {_vm.webUrlDialog=$$v},expression:"webUrlDialog"}},[_c('v-card',{staticClass:"nocard"},[_c('div',{staticClass:"header_modal gradient-text-color"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Enlace web'))+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-white float-right text-white",attrs:{"color":"transparent","elevation":"0","icon":"","small":""},on:{"click":function($event){_vm.webUrlDialog = false}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"26"}},[_vm._v(" far fa-times-circle ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('Cerrar')))])])],1),_c('v-card-text',{staticClass:"mt-8"},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('Enlace web'),"readonly":_vm.isPreviewInfoUrl},on:{"input":_vm.debounceInput},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-progress-circular',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingWebUrlContent),expression:"loadingWebUrlContent"}],attrs:{"size":"24","indeterminate":"","color":"blue"}})]},proxy:true}]),model:{value:(_vm.currentFile.url),callback:function ($$v) {_vm.$set(_vm.currentFile, "url", $$v)},expression:"currentFile.url"}}),_c('v-text-field',{attrs:{"label":_vm.$t('¿Cuándo quieres que use este enlace?'),"readonly":_vm.isPreviewInfoUrl,"maxlength":"150","counter":"150"},model:{value:(_vm.currentFile.description),callback:function ($$v) {_vm.$set(_vm.currentFile, "description", $$v)},expression:"currentFile.description"}}),_c('v-textarea',{staticClass:"text-justify",attrs:{"value":_vm.currentFile.url === _vm.webUrlPreviewInResponse ? _vm.webUrlPreviewContent : '',"label":_vm.$t('Previsualización de contenido'),"readonly":"","rows":"12"}}),(!_vm.isPreviewInfoUrl)?_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('h4',[_vm._v(" "+_vm._s(_vm.$t('Visibilidad del conocimiento'))+": "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"16"}},[_vm._v(" fas fa-circle-question ")])],1)]}}],null,false,792755369)},[_c('span',[_vm._v(_vm._s(_vm.$t('Explicación')))])])],1),_c('v-row',[_c('v-col',{attrs:{"offset":"3","cols":"6"}},[_c('v-select',{staticClass:"mt-2",attrs:{"items":_vm.audienceOptions,"outlined":"","filled":"","item-value":"value","item-text":"text"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"blue","left":""}},[_vm._v(" "+_vm._s(item.icon)+" ")]),_c('span',[_vm._v(_vm._s(_vm.$t(item.text)))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"blue","left":""}},[_vm._v(" "+_vm._s(item.icon)+" ")]),_c('span',[_vm._v(_vm._s(_vm.$t(item.text)))])]}}],null,false,3837921948),model:{value:(_vm.currentFile.audience),callback:function ($$v) {_vm.$set(_vm.currentFile, "audience", $$v)},expression:"currentFile.audience"}})],1)],1)],1)],1):_vm._e()],1)],1)],1),_c('v-divider'),(!_vm.isPreviewInfoUrl)?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"text-capitalize float-right",attrs:{"elevation":"0","large":"","text":"","rounded":"","disabled":_vm.loading,"color":"blue-grey darken-4 white--text"},on:{"click":function($event){_vm.webUrlDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('Cancelar'))+" ")]),_c('v-btn',{staticClass:"white--text text-capitalize float-right px-5",attrs:{"elevation":"0","large":"","rounded":"","disabled":!_vm.currentFile.url || !_vm.currentFile.description,"loading":_vm.loading,"color":"light-green darken-2"},on:{"click":_vm.createFileUrl}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" fas fa-save ")]),_vm._v(" "+_vm._s(_vm.$t('Guardar'))+" ")],1)],1):_vm._e()],1)],1),_c('v-dialog',{attrs:{"width":"35%"},model:{value:(_vm.fileInfoDialog),callback:function ($$v) {_vm.fileInfoDialog=$$v},expression:"fileInfoDialog"}},[_c('v-card',{staticClass:"nocard"},[_c('div',{staticClass:"header_modal gradient-text-color"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Información de archivo'))+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-white float-right text-white",attrs:{"color":"transparent","elevation":"0","icon":"","small":""},on:{"click":function($event){_vm.fileInfoDialog = false}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"26"}},[_vm._v(" far fa-times-circle ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('Cerrar')))])])],1),_c('v-card-text',{staticClass:"mt-8"},[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-sheet',{staticClass:"px-5 pb-2 pt-4",staticStyle:{"width":"220px","border":"0.12em solid #d9d9d9","margin-left":"auto","margin-right":"auto","border-radius":"5px"}},[_c('v-icon',{staticClass:"pb-4",attrs:{"color":"blue lighten-1","size":"60"}},[_vm._v(" "+_vm._s(_vm.currentFile.icon)+" ")]),_c('br'),_c('span',[_vm._v(" "+_vm._s(_vm._f("shortChars")(_vm.currentFile.filename ? _vm.currentFile.filename : '',20))+" ")])],1)],1),_c('v-col',[_c('b',[_vm._v(_vm._s(_vm.$t('Nombre'))+":")]),_vm._v(" "+_vm._s(_vm.currentFile.filename)+" "),_c('v-divider'),_c('b',[_vm._v(_vm._s(_vm.$t('Ruta'))+":")]),_vm._v(" "+_vm._s(_vm.currentFile.path ? _vm.currentFile.path[0].toUpperCase() + _vm.currentFile.path.slice(1).toLowerCase() : '')+" "),_c('v-divider'),_c('b',[_vm._v(_vm._s(_vm.$t('Tamaño'))+":")]),_vm._v(" "+_vm._s(_vm._f("formatBytes")(_vm.currentFile.bytes))+" "),_c('v-divider'),_c('b',[_vm._v(_vm._s(_vm.$t('Creado'))+":")]),_vm._v(" "+_vm._s(_vm._f("lastModified")(_vm.currentFile.created_at))+" ")],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"35%"},model:{value:(_vm.audienceDialog),callback:function ($$v) {_vm.audienceDialog=$$v},expression:"audienceDialog"}},[_c('v-card',{staticClass:"nocard",staticStyle:{"overflow":"hidden"}},[_c('div',{staticClass:"header_modal gradient-text-color"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Visibilidad del conocimiento'))+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-white float-right text-white",attrs:{"color":"transparent","elevation":"0","icon":"","small":""},on:{"click":function($event){_vm.audienceDialog = false}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"26"}},[_vm._v(" far fa-times-circle ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('Cerrar')))])])],1),_c('v-card-text',{staticClass:"mt-8"},[_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('Aqui va el texto que explica la diferencia entre hacer un conocimiento publico o privado'))+" ")])])],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-row',[_c('v-col',{attrs:{"cols":"4","offset":"8"}},[_c('v-select',{staticClass:"mt-2",attrs:{"items":_vm.audienceOptions,"outlined":"","filled":"","item-value":"value","item-text":"text"},on:{"change":_vm.saveFileAudience},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"blue","left":""}},[_vm._v(" "+_vm._s(item.icon)+" ")]),_c('span',[_vm._v(_vm._s(_vm.$t(item.text)))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"blue","left":""}},[_vm._v(" "+_vm._s(item.icon)+" ")]),_c('span',[_vm._v(_vm._s(_vm.$t(item.text)))])]}}]),model:{value:(_vm.currentFileHelper.audience),callback:function ($$v) {_vm.$set(_vm.currentFileHelper, "audience", $$v)},expression:"currentFileHelper.audience"}})],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"35%"},model:{value:(_vm.removeFileDialog),callback:function ($$v) {_vm.removeFileDialog=$$v},expression:"removeFileDialog"}},[_c('v-card',{staticClass:"nocard"},[_c('div',{staticClass:"header_modal gradient-text-color"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Eliminar conocimiento'))+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-white float-right text-white",attrs:{"color":"transparent","elevation":"0","icon":"","small":""},on:{"click":function($event){_vm.removeFileDialog = false}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"26"}},[_vm._v(" far fa-times-circle ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('Cerrar')))])])],1),_c('v-card-text',{staticClass:"mt-8"},[_c('v-row',[_c('v-col',{staticClass:"text-center"},[(_vm.currentFile.type === 0)?_c('p',[_vm._v(" "+_vm._s(_vm.$t('¿Estas seguro de eliminar el archivo'))+" "),_c('b',[_vm._v(_vm._s(_vm.currentFile.filename))]),_vm._v(" ? ")]):_vm._e(),(_vm.currentFile.type === 1)?_c('p',[_vm._v(" "+_vm._s(_vm.$t('¿Estas seguro de eliminar la url'))+" "),_c('b',[_vm._v(_vm._s(_vm.currentFile.url))]),_vm._v(" ? ")]):_vm._e()])],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"text-capitalize float-right",attrs:{"elevation":"0","large":"","text":"","rounded":"","disabled":_vm.loading,"color":"blue-grey darken-4 white--text"},on:{"click":function($event){_vm.removeFileDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('Cerrar'))+" ")]),_c('v-btn',{staticClass:"white--text text-capitalize float-right px-5",attrs:{"elevation":"0","large":"","rounded":"","loading":_vm.loading,"color":"red darken-2"},on:{"click":_vm.removeFile}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" fas fa-remove ")]),_vm._v(" "+_vm._s(_vm.$t('Eliminar'))+" ")],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"35%"},model:{value:(_vm.addFileDialog),callback:function ($$v) {_vm.addFileDialog=$$v},expression:"addFileDialog"}},[_c('v-card',{staticClass:"nocard"},[_c('div',{staticClass:"header_modal gradient-text-color"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Gestor multimedia'))+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-white float-right text-white",attrs:{"color":"transparent","elevation":"0","icon":"","small":""},on:{"click":function($event){_vm.removeFileDialog = false}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"26"}},[_vm._v(" far fa-times-circle ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('Cerrar')))])])],1),_c('v-card-text',{staticClass:"mt-8"},[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-sheet',{staticClass:"px-5 pb-2 pt-4",staticStyle:{"width":"220px","border":"0.12em solid #d9d9d9","margin-left":"auto","margin-right":"auto","border-radius":"5px"}},[_c('v-icon',{staticClass:"pb-4",attrs:{"color":"blue lighten-1","size":"60"}},[_vm._v(" "+_vm._s(_vm.currentFile.icon)+" ")]),_c('br'),_c('span',[_vm._v(" "+_vm._s(_vm._f("shortChars")(_vm.currentFile.name ? _vm.currentFile.name : '',20))+" ")])],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"preview-multimedia__text-name"},[_c('b',[_vm._v(" "+_vm._s(_vm.$t("Nombre"))+":")]),_vm._v(" "+_vm._s(_vm.currentFile.name)+" "),_c('v-divider'),_c('b',[_vm._v(" "+_vm._s(_vm.$t("Ruta"))+":")]),_vm._v(" "+_vm._s(_vm.getCapitalizedPath(_vm.currentFile.path))+" "),_c('v-divider'),_c('b',[_vm._v(" "+_vm._s(_vm.$t("Tamaño"))+":")]),_vm._v(" "+_vm._s(_vm._f("formatBytes")(_vm.currentFile.size))+" "),_c('v-divider'),_c('b',[_vm._v(" "+_vm._s(_vm.$t("Actualizado"))+":")]),_vm._v(" "+_vm._s(_vm._f("lastModified")('2010-10-10 10:10:10'))+" ")],1)])],1),(!_vm.isPreviewInfoUrl)?_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('h4',[_vm._v(" "+_vm._s(_vm.$t('Visibilidad del conocimiento'))+": "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"16"}},[_vm._v(" fas fa-circle-question ")])],1)]}}],null,false,792755369)},[_c('span',[_vm._v(_vm._s(_vm.$t('Explicación')))])])],1),_c('v-row',[_c('v-col',{attrs:{"offset":"3","cols":"6"}},[_c('v-select',{staticClass:"mt-2",attrs:{"items":_vm.audienceOptions,"outlined":"","filled":"","item-value":"value","item-text":"text"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"blue","left":""}},[_vm._v(" "+_vm._s(item.icon)+" ")]),_c('span',[_vm._v(_vm._s(_vm.$t(item.text)))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"blue","left":""}},[_vm._v(" "+_vm._s(item.icon)+" ")]),_c('span',[_vm._v(_vm._s(_vm.$t(item.text)))])]}}],null,false,3837921948),model:{value:(_vm.currentFile.audience),callback:function ($$v) {_vm.$set(_vm.currentFile, "audience", $$v)},expression:"currentFile.audience"}})],1)],1)],1)],1):_vm._e()],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"text-capitalize float-right",attrs:{"elevation":"0","large":"","text":"","rounded":"","disabled":_vm.loading,"color":"blue-grey darken-4 white--text"},on:{"click":function($event){_vm.addFileDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('Cancelar'))+" ")]),_c('v-btn',{staticClass:"white--text text-capitalize float-right px-5",attrs:{"elevation":"0","large":"","rounded":"","loading":_vm.loading,"color":"light-green darken-2"},on:{"click":_vm.saveSelectedFile}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" fas fa-save ")]),_vm._v(" "+_vm._s(_vm.$t('Guardar'))+" ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }