

export class Assistant {
  id = null
  picture = null
  assistantId = null
  object = 'assistant'
  createdAt = null
  name = null
  engineId = 1
  apiKey = null
  apiKeySource = Assistant.API_KEY_SOURCE_DEFAULT
  model = 'gpt-4o'
  description = ''
  instructions = ''
  fileIds = []
  metadata = null
  files = []
  filesToStore = []
  fileIdsToRemove = []
  fileUrlIdsToRemove = []

  communicationTones = []
  communicationObjective = null

  communicationTone = null
  role = null
  tasks = null
  availability = null
  companyName = null

  sector = null
  activities = null
  mission = null
  website = null

  welcomeMessage = null
  initConversationMessages = []
  isActiveWebBrowsing = false
  uniqueId = ''
  status = 0

  static API_KEY_SOURCE_DEFAULT = 1
  static API_KEY_SOURCE_CUSTOM = 2

  static FILE_TYPE_FILE = 0;
  static FILE_TYPE_URL = 1;

  constructor (data = null) {
    if (data) {
      this.setData(data)
    }
  }

  setData (data) {
    this.id = data.id
    this.picture = data.picture
    this.assistantId = data.assistant_id
    this.communicationTones = data.communication_tones
    this.communicationObjective = data.communication_objective
    this.welcomeMessage = data.welcome_message
    this.createdAt = data.created_at
    this.name = data.name
    this.engine = data.engine
    this.apiKey = data.api_key
    this.description = data.description
    this.model = data.model
    this.instructions = data.instructions
    this.fileIds = data.file_ids
    this.metadata = data.metadata
    this.files = data.files
    this.filesToStore = []
    this.initConversationMessages = data.init_conversation_messages ? JSON.parse(data.init_conversation_messages) : []
    this.isActiveWebBrowsing = data.is_active_web_browsing
    this.uniqueId = data.unique_id
    this.apiKeySource = Assistant.API_KEY_SOURCE_DEFAULT
    this.engineId = data.engine_id

    this.communicationTone = data.communication_tone
    this.role = data.role
    this.tasks = data.tasks
    this.availability = data.availability
    this.companyName = data.company_name
    this.sector = data.sector
    this.activities = data.activities
    this.mission = data.mission
    this.website = data.website
    this.status = data.status
  }
}
